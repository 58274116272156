import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import './all.scss'
import useSiteMetadata from '../hooks/use-site-metadata'
import { withPrefix } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const TemplateWrapper = ({ children }) => {
  const { title, description, backgroundImage } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en-NZ" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <BackgroundImage
        Tag="div"
        fluid={backgroundImage.fluid}
        className="site-bg-image"
      >
        <div id="fb-root"/>
        {children}
      </BackgroundImage>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
