import React from 'react'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="content has-text-centered has-text-weight-light">
        <p className="my-2">©2020 Amberley Farmers Market</p>
        <p className="my-2">Design by Aidan Gauland</p>
      </div>
    </footer>
  )
}

export default Footer
